import { TimeSlot } from 'components/therapistMatcher/data';
import TherapistsTimeSelector from 'components/therapistMatcher/TherapistTimeGrid';
import { AppointmentType } from 'domain/appointment/constants';
import InitialEvaluationOptions, {
  IInitialEvaluationOptions,
} from 'pages/new-client-create/SchedulingSessions/initial-evaluation-options';
import SubStep from 'pages/new-client-create/sub-step';
import { FC, SetStateAction } from 'react';
import { Therapist } from 'types';

export const EvaluationSchedulingStep: FC<{
  appointmentType?: AppointmentType;
  initialEvaluationOptions: IInitialEvaluationOptions;
  onChangeEvalOptions: (options: IInitialEvaluationOptions) => void;
  filteredTherapists: Therapist[];
  isLoadingTherapists: boolean;
  autoSelectTimezone: boolean;
  timeSlot: TimeSlot | null;
  setTimeSlot: React.Dispatch<SetStateAction<TimeSlot | null>>;
}> = ({
  appointmentType,
  initialEvaluationOptions,
  onChangeEvalOptions,
  filteredTherapists,
  autoSelectTimezone,
  timeSlot,
  setTimeSlot,
}) =>
  appointmentType === AppointmentType.Evaluation ? (
    <SubStep title="4. Schedule 60 minutes evaluation" expanded>
      <InitialEvaluationOptions value={initialEvaluationOptions} onChange={onChangeEvalOptions} />
      {initialEvaluationOptions.availableDate &&
        initialEvaluationOptions.evaluationType &&
        filteredTherapists &&
        autoSelectTimezone && (
          <TherapistsTimeSelector
            type="evaluation"
            duration={initialEvaluationOptions.evaluationType.value}
            therapists={filteredTherapists}
            value={timeSlot}
            dateFilter={initialEvaluationOptions.availableDate}
            onChange={setTimeSlot}
          />
        )}
    </SubStep>
  ) : null;
