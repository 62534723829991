import { TimeSlot } from 'components/therapistMatcher/data';
import { useDisplayTimezone } from 'hooks/common/useDisplayTimezone';
import { TimezoneOption } from 'hooks/common/useDisplayTimezone/options';
import React from 'react';
import 'twin.macro';
import ReadOnlyField from '../ReadOnlyField';
import { ScheduledSessions } from '../SchedulingSessions/schedule-sessions';
import ConflictWarning from '../../../components/ConflictWarning';

interface ScheduleSummaryProps {
  scheduledSessionsInfo: ScheduledSessions;
}

export function describeSessionTime(
  sessionTime: TimeSlot | null | undefined,
  displayTimezone: TimezoneOption,
  sessionName: string,
) {
  const therapistName = sessionTime?.therapist.therapistName;
  const timestamp = sessionTime?.timestamp?.tz(displayTimezone.value);

  if (!sessionTime || !timestamp) {
    return `No ${sessionName} has been scheduled`;
  }

  return `${sessionTime?.duration} minute ${sessionName} with ${therapistName} SLP at ${timestamp?.format('h:mm A')} (${
    displayTimezone.label
  }) on ${timestamp?.format('dddd, M/D/YYYY')}`;
}

export function describeRecurringSessionTime(
  sessionTime: TimeSlot | null | undefined,
  displayTimezone: TimezoneOption,
  sessionName: string,
  notSchedulingReason: string | null,
) {
  const therapistName = sessionTime?.therapist.therapistName;
  const timestamp = sessionTime?.timestamp?.tz(displayTimezone.value);

  if (!sessionTime || !timestamp) {
    const reasonText = notSchedulingReason ? ` (${notSchedulingReason})` : '';
    return `No ${sessionName} has been scheduled${reasonText}`;
  }

  return `${sessionTime?.duration} minute ${sessionName} with ${therapistName} SLP on ${timestamp.format(
    'dddd',
  )}s at ${timestamp?.format('h:mm A')} (${displayTimezone.label}) starting on ${timestamp?.format('M/D/YYYY')}`;
}

const ScheduleSummary = ({ scheduledSessionsInfo }: ScheduleSummaryProps) => {
  const { displayTimezone } = useDisplayTimezone();

  const {
    evaluationTime = null,
    firstRecurringSessionInfo = null,
    secondRecurringSessionInfo = null,
  } = scheduledSessionsInfo.skipped ? {} : scheduledSessionsInfo;

  return (
    <div tw="flex flex-col gap-6">
      <ReadOnlyField label="Initial Evaluation">
        <span>{describeSessionTime(evaluationTime, displayTimezone, 'evaluation')}</span>
      </ReadOnlyField>

      {evaluationTime && (
        <ConflictWarning
          className="self-start"
          timeSlot={evaluationTime}
          displayTimezone={displayTimezone}
          type="evaluation"
        />
      )}

      <ReadOnlyField label="Recurring Session 1">
        <span>
          {describeRecurringSessionTime(firstRecurringSessionInfo?.timestamp, displayTimezone, 'session', null)}
        </span>
      </ReadOnlyField>

      {firstRecurringSessionInfo?.timestamp && (
        <ConflictWarning
          className="self-start"
          timeSlot={firstRecurringSessionInfo?.timestamp}
          displayTimezone={displayTimezone}
          type="session"
        />
      )}

      <ReadOnlyField label="Recurring Session 2">
        <span>
          {describeRecurringSessionTime(
            secondRecurringSessionInfo?.timestamp,
            displayTimezone,
            'session',
            secondRecurringSessionInfo?.notSchedulingReason ?? null,
          )}
        </span>
      </ReadOnlyField>

      {secondRecurringSessionInfo?.timestamp && (
        <ConflictWarning
          className="self-start"
          timeSlot={secondRecurringSessionInfo?.timestamp}
          displayTimezone={displayTimezone}
          type="session"
        />
      )}
    </div>
  );
};

export default ScheduleSummary;
