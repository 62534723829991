export enum EligibilityLabel {
  Active = 'Eligible',
  Inactive = 'Eligibility Issue',
}

export enum EligibilityStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export const EligibilityViewOptions = {
  Active: { value: EligibilityStatus.Active, label: EligibilityLabel.Active },
  Inactive: { value: EligibilityStatus.Inactive, label: EligibilityLabel.Inactive },
};

export const ReadableDateTimeFormat = 'MM/DD/YYYY hh:mm A';

export const UnknownEligibilityError = 'Unknown error';