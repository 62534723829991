import { yupResolver } from '@hookform/resolvers/yup';
import { AppointmentType } from 'domain/appointment/constants';
import { useDisplayTimezone } from 'hooks/common/useDisplayTimezone';

import { cond, stubTrue, toLower } from 'lodash';
import { ClientTab } from 'pages/client/client';
import { useMemo, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { usStatesOptionsWithMultipleTimezones, getSelectedTimeSlot } from '../helpers';
import { clientMatchingSchema } from '../schema';
import { useClientMatching } from './useClientMatching';
import { useFilteredTherapists } from 'hooks/common/useTherapistFilters/use-filtered-therapists';

import * as Sentry from '@sentry/react';
import { useQueryClient } from 'react-query';
import { specialties } from 'hooks/common/useTherapistFilters/options';
import { SelectOption } from 'types';
import { useContentfulEntry } from 'hooks/use-contentful';
import { INSURANCES_ACCEPTED_ENTRY_ID } from 'hooks/use-ContentfulData';

export const useClientMatchingForm = (clientId: string, setTab: React.Dispatch<React.SetStateAction<ClientTab>>) => {
  const queryClient = useQueryClient();

  const timezone = useDisplayTimezone();
  const history = useHistory();
  const form = useForm({
    resolver: yupResolver(clientMatchingSchema),
  });

  const {
    data: { insurances = [] } = {},
    isLoading: isLoadingInsurances,
    isSuccess,
  } = useContentfulEntry({
    entryId: INSURANCES_ACCEPTED_ENTRY_ID,
  });

  const {
    primaryContact,
    autoSelectTimezone,
    initialEvaluationOptions,
    isLoadingMutation,
    timeSlot,
    setTimeSlot,
    onChangeEvalOptions,
    createAppointmentSession,
    setAutoSelectTimezone,
    createAppointmentEvaluation,
    updateClientAsync,
    client,
  } = useClientMatching(clientId);

  const clientSpecialties = useMemo(() => {
    if (!client) return [];

    const clientSpecialty = specialties.filter((specialty: string) =>
      client.clinicalCompetencies?.map(toLower).includes(specialty.toLowerCase()),
    );

    return clientSpecialty.map(e => ({ value: e, label: e })) as SelectOption[];
  }, [client]);

  const clientInsurances = useMemo(() => {
    if (isLoadingInsurances || !isSuccess || !client) return null;

    const clientInsurances = insurances.filter((insurance: string) =>
      client.insurancePlan?.map(toLower).includes(insurance.toLowerCase()),
    );

    return clientInsurances.map((e: string) => ({ value: e, label: e })) as SelectOption[];
  }, [isLoadingInsurances, isSuccess, client]);

  const {
    filters,
    isLoading: isLoadingTherapists,
    onChangeFilters,
    filteredTherapists,
  } = useFilteredTherapists({
    state: usStatesOptionsWithMultipleTimezones.find(state => state.value === primaryContact?.address?.state) ?? null,
    specialty: clientSpecialties,
    insurancePlan: clientInsurances!,
  });

  const appointmentType = useWatch({ control: form.control, name: 'appointmentType' });
  const sessionType = useWatch({ control: form.control, name: 'sessionType' });

  const selectedTimeSlot = useMemo(
    () => getSelectedTimeSlot(appointmentType, timeSlot, sessionType),
    [appointmentType, sessionType, timeSlot],
  );
  const canGoNext = useMemo(
    () =>
      appointmentType === AppointmentType.Therapy ? !!sessionType : appointmentType === AppointmentType.Evaluation,
    [appointmentType, sessionType],
  );

  const handleSubmit = async () => {
    const createAppointment = cond([
      [
        ({ appointmentType }: { appointmentType: AppointmentType; onSuccess: () => void }) =>
          appointmentType === AppointmentType.Therapy,
        async ({ onSuccess }) =>
          await createAppointmentSession(timeSlot, sessionType, timezone.displayTimezone.value, onSuccess),
      ],
      [
        ({ appointmentType }: { appointmentType: AppointmentType; onSuccess: () => void }) =>
          appointmentType === AppointmentType.Evaluation,
        async ({ onSuccess }) => await createAppointmentEvaluation(timeSlot, timezone.displayTimezone.value, onSuccess),
      ],
      [
        stubTrue,
        () => {
          throw new Error('FATAL ::: Unsupported appointment type');
        },
      ],
    ]);

    await createAppointment({
      appointmentType,
      onSuccess: async () => {
        try {
          if (client) {
            const {
              clientFirstName,
              clientMiddleName,
              clientLastName,
              isClientMinor,
              weight,
              height,
              dob,
              sex,
              genderIdentity,
              preferredFirstName,
              suffix,
            } = client;
            const { insurancePlan, specialty } = filters;

            await updateClientAsync({
              clientId,
              clientInformation: {
                clientFirstName,
                clientMiddleName,
                clientLastName,
                isClientMinor,
                dob,
                sex,
                genderIdentity,
                preferredFirstName,
                weight,
                height,
                suffix,
                insurancePlan: insurancePlan.map(e => e.value),
                clinicalCompetencies: specialty.map(e => e.value),
              },
            });

            queryClient.invalidateQueries();
          }
        } catch (error) {
          Sentry.captureException("WARNING ::: Failed to update client's information.", {
            extra: {
              clientId,
              source: 'client-matching',
              reason: 'failed to update client competencies and insurance plan after creating appointment',
              therapist: timeSlot?.therapist,
            },
          });
        }

        form.reset();
        setTimeSlot(null);
        history.push(`/clients/${clientId}#overview`);
        setTab(ClientTab.Overview);
      },
    });
  };

  useEffect(() => setTimeSlot(null), [appointmentType]);

  return {
    form,
    appointmentType,
    sessionType,
    canGoNext,
    filters,
    onChangeFilters,
    autoSelectTimezone,
    setAutoSelectTimezone,
    initialEvaluationOptions,
    onChangeEvalOptions,
    isLoadingTherapists,
    filteredTherapists,
    timeSlot,
    setTimeSlot,
    selectedTimeSlot,
    handleSubmit,
    isLoadingMutation,
    primaryContact,
  };
};
