import React from 'react';
import {
  addClientDiagnoses,
  addMedicalDiagnoses,
  addAreasOfFunction,
  addApplicableConditions,
  addSkilledInterventions,
  addClientVisitFrequency,
  appointmentCancelled,
  appointmentLateCancelled,
  cancelAcuityAppointment,
  appointmentNoShow,
  appointmentNote,
  appointmentRescheduled,
  rescheduleAcuityAppointment,
  chartNote,
  adminNote,
  createClient,
  evaluationNote,
  intakeAdult,
  intakeChild,
  intakeEmailSent,
  removeClientDiagnoses,
  removeMedicalDiagnoses,
  removeAreasOfFunction,
  removeApplicableConditions,
  removeSkilledInterventions,
  removeClientVisitFrequency,
  soapNote,
  updateClientVisitFrequency,
  screeningIntakeChild,
  screeningNote,
  addCarePlanGoals,
  updateCarePlanGoal,
  sessionNote,
  homeProgramSubmission,
  addBillingCredits,
  removeBillingCredits,
  RefundBillingCharge,
  UpdateBillingInfoCopay,
  twilioMessage,
  inactivateClient,
  reactivateClient,
  addClientAdditionalGoal,
  updateClientAdditionalGoal,
  removeClientAdditionalGoal,
  addAcuityAppointment,
  dischargeNote,
  addPendingReschedule,
  reschedulePendingReschedule,
  clientAssignmentCompleted,
  changeCarePlanQualityReviewEvaluationStatus,
  changeCarePlanQualityReviewDischargeStatus,
  releaseOfInformationFormCompleted,
  filesUploadedClient,
  filesDeletedClient,
} from './event-renderers';
import { Event } from '../types';
import { ChunkErrorBoundary } from './event-renderers/chunk-error-boundary';
import updateSingleValueRenderer from './event-renderers/update-single-value-renderer';
import addSingleValueRenderer from './event-renderers/add-single-value-renderer';
import removeSingleValueRenderer from './event-renderers/remove-single-value-renderer';
import dayjs from 'dayjs';
import addValueListRenderer from './event-renderers/add-value-list-renderer';
import removeValueListRenderer from './event-renderers/remove-value-list-renderer';
import { ErrorBoundary } from '@sentry/react';
import EventRenderFallback from './event-renderers/event-render-fallback';

const eventRenderers = new Map();
eventRenderers.set('add-client-diagnoses', addClientDiagnoses);
eventRenderers.set('add-medical-diagnoses', addMedicalDiagnoses);
eventRenderers.set('add-areas-of-function', addAreasOfFunction);
eventRenderers.set('add-applicable-conditions', addApplicableConditions);
eventRenderers.set('add-skilled-interventions', addSkilledInterventions);
eventRenderers.set('add-client-visit-frequency', addClientVisitFrequency);
eventRenderers.set('appointment-cancelled', appointmentCancelled);
eventRenderers.set('appointment-late-cancelled', appointmentLateCancelled);
eventRenderers.set('cancel-acuity-appointment', cancelAcuityAppointment);
eventRenderers.set('appointment-no-show', appointmentNoShow);
eventRenderers.set('appointment-note', appointmentNote);
eventRenderers.set('appointment-rescheduled', appointmentRescheduled);
eventRenderers.set('reschedule-acuity-appointment', rescheduleAcuityAppointment);
eventRenderers.set('chart-note', chartNote);
eventRenderers.set('admin-note', adminNote);
eventRenderers.set('create-client', createClient);
eventRenderers.set('add-acuity-appointment', addAcuityAppointment);
eventRenderers.set('evaluation-note', evaluationNote);
eventRenderers.set('intake-adult', intakeAdult);
eventRenderers.set('intake-child', intakeChild);
eventRenderers.set('intake-email-sent', intakeEmailSent);
eventRenderers.set('remove-client-diagnoses', removeClientDiagnoses);
eventRenderers.set('remove-medical-diagnoses', removeMedicalDiagnoses);
eventRenderers.set('remove-areas-of-function', removeAreasOfFunction);
eventRenderers.set('remove-applicable-conditions', removeApplicableConditions);
eventRenderers.set('remove-skilled-interventions', removeSkilledInterventions);
eventRenderers.set('remove-client-visit-frequency', removeClientVisitFrequency);
eventRenderers.set('soap-note', soapNote);
eventRenderers.set('update-client-visit-frequency', updateClientVisitFrequency);
eventRenderers.set('screening-intake-child', screeningIntakeChild);
eventRenderers.set('screening-note', screeningNote);
eventRenderers.set('add-client-goal', addCarePlanGoals);
eventRenderers.set('update-client-goal', updateCarePlanGoal);
eventRenderers.set('session-note', sessionNote);
eventRenderers.set('home-program-submission', homeProgramSubmission);
eventRenderers.set('add-billing-credits', addBillingCredits);
eventRenderers.set('remove-billing-credits', removeBillingCredits);
eventRenderers.set('refund-billing-charge', RefundBillingCharge);
eventRenderers.set('update-billing-info-copay', UpdateBillingInfoCopay);
eventRenderers.set('twilio-message', twilioMessage);
eventRenderers.set('inactivate-client', inactivateClient);
eventRenderers.set('reactivate-client', reactivateClient);
eventRenderers.set(
  'add-client-discharge-plan',
  addSingleValueRenderer({
    '1.0': {
      fieldName: 'details',
      description: 'discharge plan',
    },
  }),
);
eventRenderers.set(
  'remove-client-discharge-plan',
  removeSingleValueRenderer({
    '1.0': {
      fieldName: 'details',
      description: 'discharge plan',
    },
  }),
);
eventRenderers.set(
  'update-client-discharge-plan',
  updateSingleValueRenderer({
    '1.0': {
      fieldName: 'dischargePlan',
      formatValue: value => value.details,
      description: 'discharge plan',
    },
  }),
);
eventRenderers.set(
  'add-client-start-date',
  addSingleValueRenderer({
    '1.0': {
      formatValue: value => dayjs(value).format('MM/DD/YYYY'),
      description: 'start date',
    },
  }),
);
eventRenderers.set(
  'remove-client-start-date',
  removeSingleValueRenderer({
    '1.0': {
      formatValue: value => dayjs(value).format('MM/DD/YYYY'),
      description: 'start date',
    },
  }),
);
eventRenderers.set(
  'update-client-start-date',
  updateSingleValueRenderer({
    '1.0': {
      fieldName: 'startDate',
      formatValue: value => dayjs(value).format('MM/DD/YYYY'),
      description: 'start date',
    },
  }),
);
eventRenderers.set(
  'add-client-end-date',
  addSingleValueRenderer({
    '1.0': {
      formatValue: value => dayjs(value).format('MM/DD/YYYY'),
      description: 'end date',
    },
  }),
);
eventRenderers.set(
  'remove-client-end-date',
  removeSingleValueRenderer({
    '1.0': {
      formatValue: value => dayjs(value).format('MM/DD/YYYY'),
      description: 'end date',
    },
  }),
);
eventRenderers.set(
  'update-client-end-date',
  updateSingleValueRenderer({
    '1.0': {
      fieldName: 'endDate',
      formatValue: value => dayjs(value).format('MM/DD/YYYY'),
      description: 'end date',
    },
  }),
);
eventRenderers.set(
  'add-client-visit-duration',
  addSingleValueRenderer({
    '1.0': {
      description: 'visit duration',
    },
  }),
);
eventRenderers.set(
  'remove-client-visit-duration',
  removeSingleValueRenderer({
    '1.0': {
      description: 'visit duration',
    },
  }),
);
eventRenderers.set(
  'update-client-visit-duration',
  updateSingleValueRenderer({
    '1.0': {
      fieldName: 'visitDuration',
      description: 'visit duration',
    },
  }),
);
eventRenderers.set(
  'add-client-therapy-purpose',
  addSingleValueRenderer({
    '1.0': {
      fieldName: 'details',
      description: 'therapy purpose',
    },
  }),
);
eventRenderers.set(
  'remove-client-therapy-purpose',
  removeSingleValueRenderer({
    '1.0': {
      fieldName: 'details',
      description: 'therapy purpose',
    },
  }),
);
eventRenderers.set(
  'update-client-therapy-purpose',
  updateSingleValueRenderer({
    '1.0': {
      fieldName: 'therapyPurpose',
      formatValue: value => value.details,
      description: 'therapy purpose',
    },
  }),
);
eventRenderers.set(
  'add-client-delays-disabilities',
  addSingleValueRenderer({
    '1.0': {
      formatValue: value => (value.present ? `Yes - ${value.severity}` : 'No'),
      description: 'developmental delays or chronic disabilities',
    },
  }),
);
eventRenderers.set(
  'remove-client-delays-disabilities',
  removeSingleValueRenderer({
    '1.0': {
      formatValue: value => (value.present ? `Yes - ${value.severity}` : 'No'),
      description: 'developmental delays or chronic disabilities',
    },
  }),
);
eventRenderers.set(
  'update-client-delays-disabilities',
  updateSingleValueRenderer({
    '1.0': {
      fieldName: 'delaysDisabilities',
      formatValue: value => (value.present ? `Yes - ${value.severity}` : 'No'),
      description: 'developmental delays or chronic disabilities',
    },
  }),
);
eventRenderers.set(
  'add-client-total-visits',
  addSingleValueRenderer({
    '1.0': {
      description: 'total visits',
    },
  }),
);
eventRenderers.set(
  'remove-client-total-visits',
  removeSingleValueRenderer({
    '1.0': {
      description: 'total visits',
    },
  }),
);
eventRenderers.set(
  'update-client-total-visits',
  updateSingleValueRenderer({
    '1.0': {
      fieldName: 'totalVisits',
      description: 'total visits',
    },
  }),
);
eventRenderers.set(
  'add-equipment-devices',
  addValueListRenderer({
    '1.0': {
      singularDescription: 'adaptive equipment or assistive device',
      pluralDescription: 'adaptive equipment or assistive devices',
    },
  }),
);
eventRenderers.set(
  'remove-equipment-devices',
  removeValueListRenderer({
    '1.0': {
      singularDescriptionWithArticle: 'an adaptive equipment or assistive device',
      pluralDescription: 'adaptive equipment or assistive devices',
    },
  }),
);
eventRenderers.set('add-client-additional-goal', addClientAdditionalGoal);
eventRenderers.set('update-client-additional-goal', updateClientAdditionalGoal);
eventRenderers.set('remove-client-additional-goal', removeClientAdditionalGoal);
eventRenderers.set('discharge-note', dischargeNote);
eventRenderers.set('add-pending-reschedule', addPendingReschedule);
eventRenderers.set('reschedule-pending-reschedule', reschedulePendingReschedule);
eventRenderers.set('client-assignment-completed', clientAssignmentCompleted);
eventRenderers.set('files-uploaded-client', filesUploadedClient);
eventRenderers.set('files-deleted-client', filesDeletedClient);
eventRenderers.set('change-care-plan-quality-review-evaluation-status', changeCarePlanQualityReviewEvaluationStatus);
eventRenderers.set('change-care-plan-quality-review-discharge-status', changeCarePlanQualityReviewDischargeStatus);
eventRenderers.set('release-of-information-form-completed', releaseOfInformationFormCompleted);

export function isEventSupported(eventType: string) {
  return [...eventRenderers.keys()].includes(eventType);
}
export default async function renderEvent(
  event: Event,
  onEdit: (id: string, ref: HTMLDivElement) => void,
  isBeenEdited: boolean,
  isLastEvent: boolean,
  index: number,
) {
  const renderFunction = eventRenderers.get(event.eventType);
  if (renderFunction) {
    const RenderComponent = await renderFunction.default(event.version);

    if (process.env.REACT_APP_NODE_ENV !== 'production') {
      console.debug(`[DEBUG] Rendering component for eventType: ${event.eventType},version ${event.version} `);
    }

    const Fallback = <EventRenderFallback isLastEvent={false /* [Diogo] isLastEvent is always returning true; use false for better visuals */} />;

    if (RenderComponent) {
      return (
        <ErrorBoundary key={event?.eventID} fallback={Fallback}>
          <ChunkErrorBoundary>
            <RenderComponent
              key={event.eventID}
              event={event}
              onEdit={onEdit}
              isBeenEdited={isBeenEdited}
              isLastEvent={isLastEvent}
            />
          </ChunkErrorBoundary>
        </ErrorBoundary>
      );
    } else {
      return (
        <ErrorBoundary key={event?.eventID} fallback={Fallback}>
          <ChunkErrorBoundary key={event?.eventID}>{RenderComponent}</ChunkErrorBoundary>
        </ErrorBoundary>
      );
    }
  } else {
    if (process.env.REACT_APP_NODE_ENV !== 'production') {
      console.debug(`[DEBUG] No render function is associated with the following event: ${event.eventType}`);
    }
    return null;
  }
}
