import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';
import { dynamicHttp } from '@expressable/utils';
import { Client, UseModifyClientEligibilityParams } from 'domain/eligibility/types';

const httpHealthRecord = dynamicHttp('health-record');

export const getClientsByEligibility = async (eligible: boolean): Promise<Client[]> => {
  const { data } = await httpHealthRecord.get(`/clients`, { params: { eligible } });

  return data;
};

export function useClientsEligibility(eligible: boolean) {
  return useQuery(
    ['clients-eligibility', eligible],
    () => getClientsByEligibility(eligible),
    { refetchOnWindowFocus: false }
  );
}

const modifyClientEligibility = async (client: Client): Promise<Client> => {
  const { data } = await httpHealthRecord.post(`/clients/insurance-eligibility/${client.id}`);

  return data;
};

export function useModifyClientEligibility({
  onSuccess: successCallback,
  onError: errorCallback
}: UseModifyClientEligibilityParams) {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(modifyClientEligibility, {
    onSuccess: (data, payload) => {
      successCallback(data, payload);
      addToast('Client eligibility updated successfully.', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['clients-eligibility'], {
        refetchActive: false,
      });
    },
    onError: (error, payload) => {
      errorCallback(error, payload);
      addToast(
        `Something happened trying to Re-run Eligibility for ${payload.clientFirstName} ${payload.clientLastName}. Please try again or contact support.`,
        { appearance: 'error', autoDismiss: true }
      );

      presentMutationError;
    },
  });
}
