import { FC } from 'react';
import { ClientMatchingState } from '../client-matching-ui';
import ReadOnlyField from 'pages/new-client-create/ReadOnlyField';
import { describeRecurringSessionTime, describeSessionTime } from 'pages/new-client-create/ScheduleSummary';
import { useDisplayTimezone } from 'hooks/common/useDisplayTimezone';

import { Button, Card } from '@expressable/ui-library';
import { AppointmentType, SessionType } from 'domain/appointment/constants';
import { useWatch } from 'react-hook-form';
import ConflictWarning from 'components/ConflictWarning';

type Props = {
  timeSlot: ClientMatchingState;
  isLoading: boolean;
  contactTimezone?: string;
};

export const ClientMatchingSummary: FC<Props> = ({ timeSlot, isLoading, contactTimezone }) => {
  const { displayTimezone } = useDisplayTimezone();
  const sessionType = useWatch({ name: 'sessionType' });
  const appointmentType = useWatch({ name: 'appointmentType' });
  const isRecurring = sessionType === SessionType.Recurring;

  return (
    <Card id="client-matching-summary" className="flex flex-col gap-y-4 mt-8">
      <div tw="font-semibold text-lg">Schedule Summary</div>

      {appointmentType === AppointmentType.Therapy && (
        <>
          <ReadOnlyField label={`Scheduled ${isRecurring ? 'Recurring' : 'Single'} Session`}>
            {isRecurring
              ? describeRecurringSessionTime(timeSlot?.slot.timeSlot, displayTimezone, 'recurring session', null)
              : describeSessionTime(timeSlot?.slot.timeSlot, displayTimezone, 'session')}
          </ReadOnlyField>

          {timeSlot?.slot.timeSlot && (
            <ConflictWarning
              className="self-start"
              timeSlot={timeSlot.slot.timeSlot}
              displayTimezone={displayTimezone}
              type="session"
              maxCheckedDates={isRecurring ? undefined : 1}
              contactTimezone={contactTimezone}
            />
          )}
        </>
      )}

      {appointmentType === AppointmentType.Evaluation && (
        <>
          <ReadOnlyField label="Initial Evaluation">
            {describeSessionTime(timeSlot?.slot.timeSlot, displayTimezone, 'evaluation')}
          </ReadOnlyField>

          {timeSlot?.slot.timeSlot && (
            <ConflictWarning
              className="self-start"
              timeSlot={timeSlot.slot.timeSlot}
              displayTimezone={displayTimezone}
              type="evaluation"
              contactTimezone={contactTimezone}
            />
          )}
        </>
      )}

      <Button disabled={isLoading} loading={isLoading} variant="primary" className="sm:w-1/6 lg:w-[10%]" type="submit">
        Schedule
      </Button>
    </Card>
  );
};
