import dayjs, { Dayjs } from 'dayjs';

type WeekPeriod = [Dayjs, Dayjs];

export const generateWeekPeriods = (originDate: Dayjs, numberOfWeeks = 1): WeekPeriod[] => {
  const weekPeriods: WeekPeriod[] = [];
  const numberOfDays = numberOfWeeks * 7;

  // generate the first week period using `originDate` as the starting point
  let startDate = dayjs.utc(originDate);
  let endDate = startDate.add(numberOfDays - 1, 'day'); // one day before the next week period

  // push the first week period
  weekPeriods.push([startDate, endDate]);

  // generate subsequent periods by adding `numberOfDays` on each iteration
  const now = dayjs();
  while (endDate.isBefore(now)) {
    startDate = startDate.add(numberOfDays, 'day');
    endDate = endDate.add(numberOfDays, 'day');

    // putting it in the first place to end up in desc order
    weekPeriods.unshift([startDate, endDate]);
  }

  return weekPeriods;
};
