import React, { createContext, useContext, useMemo } from 'react';
import { ContentfulDropdownContent, ContentfulStringContent, CptCode, SelectOption } from 'types';
import { EntryId, useContentfulEntry } from './use-contentful';
import { convertStringArrayToSelectOptions } from 'domain/notes/mapHelpers';

export enum EvaluationEntriesIds {
  EvaluationCPTs = '1Q9vSh0DqNV1fvIpJQaT4E',
  EvaluationDiagnoses = '1lEFftlrLOoyW9KilNafkp',
  EvaluationLanguagesDropdown = '3hRX7d180xZbyT41rFfPBC',
  Referrals = '2MxA4k3j31GblTf6bTEaVI',
  birthHistoryDefaultResponse = '1CEa1UJbbPlm7XkZuO7WER',
  hospitalizationHistoryResponse = '2krpougr5eM3budHeoldSU',
  allergiesHistoryResponse = 'V1zjhaq7RsS0hGx26rLCe',
  medicalDiagnosesResponse = '1FYijZ8zM2D5sVkzUlGUjr',
  medicationsResponse = '4kGX7IZTHtGLsFKEHsOSqt',
  hearingHistoryResponse = '5gpu3B2NtJELPYeg3xnGIA',
  visionHistoryResponse = '1MWljfsfznFFGjjOyALAqh',
  otherHistoryResponse = '7r1I0L1XtQKd4rT8KwHlnT',
  previousSpeechTreatment = '7nBsr8nAjz2tCByO1CCjmt',
  nursingOtherOptionsResponse = '1mzEtY91MSiMcmfAk1amei',
  CurrentEducationStatus = '5MUHuVTCswRMClqia1q5N0',
  CurrentGradeLevel = '1mKJARuNtA2Z888PBAXrEJ',
  ClientCurrentlyLives = '2MJnNogAotDANT4oSuOWP8',
  TaskObserved = '1nsd2k69f8fyNGmHFr59xt',
  ClinicalAreas = 'sk4ebrRByyRnjZDQxm2kb',
  Strengths = 'fZWaugQjVRArgtxOSL95j',
  Weaknesses = 'NwKTfDnFd6Pf3WjL677fj',
  DevelopmentalHistory = 'SIrP7s8XlCF8OT77rtkTm',
  FunctionalLimitationsResponse = '13vUyeRNECyeyYq45OzPAc',
  PainResponse = '25YdAf2lbyFKNiBGh1kmMd',
  MentalStatusResponse = '7a5WSJ4HQWmMIBSGMssMra',
  AttentionObservations = 'm7v92VnCydaSr4jLY9F6X',
  AcademicConcerns = '5ecbRNDMVTnxfsII45FlMK',
  BehavioralObservations = '61CTZH7pD5EgCx3CiDDbdC',
  SkilledInterventions = '214il2O6U84C5EEF90KFmi',
  AssessmentTypes = '3MbiLwoVBdmRsQSEAsJBHM',
  Assessments = '4TnkS65ewEUwCuppYGrv9k',
  ReceptiveLanguageConclusion = '21h8KZwFYUR85z529jPfJV',
  ExpressiveLanguageConclusion = '1W2XDfElbcbU2HJ68KP3fA',
  FluencyConclusion = '2ROIrrqbkck7z4gWpNYHFf',
  VoiceResonanceConclusion = '3PrMULPOvyu7PkCW7cabLA',
  ArticulationPhonologyConclusion = '25luKlvkYJTKn0JECcz5nP',
  OralMotorAbilitiesConclusion = '5wIthxdKRkTHzND5xzWx5q',
  FeedingShallowingConclusion = '3r3WG5ynij4QRKKdJwPxLh',
  PragmaticsConclusion = '13y79de1wjgp7NS3AwlDpz',
  NoFurtherAssessmentConclusions = '2ajxgdkivggyDprSXijA3j',
  TeletherapyAppropiate = '5u35NwODv3nYwbYdcDiv10',
  DurationOfVisits = '4Xt4Odr8hwTAj8zU5R1rO9',
  SourceOfMedicalHistory = '6DY8knRmtrHRPIgNYLVSbB',
  PrimaryTherapyPurpose = '48DNmDlVDhBSDR12GIRTyK',
  SourceOfLanguages = '626loSGQUkxI2dVPvq1qXW',
}

interface EvaluationContentfulProviderProps {
  children: React.ReactNode;
}

interface EvaluationContentfulProviderContextValue {
  data: Record<string, unknown>;
  isLoading: boolean;
  isError: boolean;
}

const EvaluationContentfulContext = createContext<EvaluationContentfulProviderContextValue>({
  data: {},
  isLoading: false,
  isError: false,
});

export function EvaluationContentfulProvider({ children }: EvaluationContentfulProviderProps) {
  const {
    data = {},
    isLoading,
    isError,
  } = useContentfulEntry({
    entryId: EntryId.EvaluationV3,
    unwrapArray: true,
  });

  const value = useMemo<EvaluationContentfulProviderContextValue>(() => {
    return {
      data,
      isLoading,
      isError,
    } as EvaluationContentfulProviderContextValue;
  }, [data, isLoading, isError]);

  return <EvaluationContentfulContext.Provider value={value}>{children}</EvaluationContentfulContext.Provider>;
}

export function useEvaluationContentfulProvider() {
  return useContext(EvaluationContentfulContext)!;
}

export function useEvaluationContentfulEntry<T>(entryId: EvaluationEntriesIds): {
  data: T;
  isLoading: boolean;
  isError: boolean;
} {
  const { data, isLoading, isError } = useEvaluationContentfulProvider();

  return {
    data: data[entryId] as unknown as T,
    isLoading,
    isError,
  };
}

interface EvaluationCPTs {
  cptCodes: {
    cptCode: string;
    cptDescription: string;
  }[];
}

export function useEvaluationCPTs() {
  const { data: rawEvaluationCPTs } = useEvaluationContentfulEntry<EvaluationCPTs>(EvaluationEntriesIds.EvaluationCPTs);
  const result = useMemo(() => {
    const isOnDraft = (cpt: { cptCode: string; cptDescription: string }) => cpt?.cptCode && cpt?.cptDescription;
    const evaluationCPTs: CptCode[] = rawEvaluationCPTs?.cptCodes
      ?.filter(isOnDraft)
      ?.map(({ cptCode, cptDescription }) => ({
        code: cptCode,
        description: cptDescription,
      }));

    const evaluationCPTsOptions: SelectOption<CptCode>[] = evaluationCPTs?.map(cpt => ({
      label: `${cpt.code} - ${cpt.description}`,
      value: cpt,
    }));

    const defaultCPTOption = evaluationCPTsOptions?.find(({ value }) => value.code === '92523');

    return { evaluationCPTs, evaluationCPTsOptions, defaultCPTOption };
  }, [rawEvaluationCPTs]);

  return result;
}

export function useEvaluationContentfulSelectOptions(entryId: EvaluationEntriesIds) {
  const { data } = useEvaluationContentfulEntry<ContentfulDropdownContent>(entryId);

  return useMemo(() => convertStringArrayToSelectOptions(data?.dropdownContent), [data]);
}

export function useEvaluationContentfulString(entryId: EvaluationEntriesIds) {
  const { data } = useEvaluationContentfulEntry<ContentfulStringContent>(entryId);

  return data?.content;
}
