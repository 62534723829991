import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'twin.macro';

interface Props {
  onChange?: (value?: Dayjs) => void;
  value?: Dayjs | null;
  error?: { message?: string };
  id: string;
  nullable?: boolean;
}
export default function DayJsDatePicker({ onChange, value, id, error, nullable }: Props) {
  const [focused, setFocused] = useState<boolean>(false);

  return (
    <div>
      <SingleDatePicker
        date={value ? moment(value?.toISOString()) : null}
        noBorder
        block
        openDirection="down"
        small
        numberOfMonths={1}
        isOutsideRange={() => false}
        focused={focused}
        onDateChange={date => onChange?.(date ? dayjs(date?.toISOString()) : undefined)}
        onFocusChange={({ focused }) => setFocused(focused)}
        id={id}
      />
      <div className="text-xs italic text-red-500">{error?.message}</div>
    </div>
  );
}
