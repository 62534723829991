import { RadioGroup } from "components/Forms/RadioGroup";
import { appointmentTypeOptions } from "domain/appointment/constants";
import SubStep from "pages/new-client-create/sub-step";
import { FC } from "react";

export const AppointmentTypeStep: FC = () => (
  <SubStep title="1. Select appointment type" expanded>
    <RadioGroup
      containerClass="space-y-2"
      inputClass="w-full inline-flex space-x-6"
      name="appointmentType"
      optional={false}
      options={appointmentTypeOptions}
    />
  </SubStep>
);