import { LoadingText } from '@expressable/ui-library';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TimeSlot, useTherapistValidateTime } from 'components/therapistMatcher/data';
import dayjs from 'dayjs';
import { TimezoneOption, timezoneOptions } from 'hooks/common/useDisplayTimezone/options';
import { AvailabilityType } from 'hooks/common/useTherapistSchedule';
import 'twin.macro';
import * as Sentry from '@sentry/react';

const ConflictWarning = ({
  className,
  timeSlot: { therapist, timestamp, duration, override },
  displayTimezone,
  contactTimezone,
  type,
  maxCheckedDates,
}: {
  className?: string;
  timeSlot: TimeSlot;
  displayTimezone: TimezoneOption;
  contactTimezone?: string;
  type: AvailabilityType;
  maxCheckedDates?: number;
}) => {
  let therapistStandardTimeZone = timezoneOptions.find(({ local }) => local === therapist.therapistTimeZone)?.value;
  if (!therapistStandardTimeZone) {
    if (Number(therapist.acuityCalendarID)) {
      Sentry.captureException(new Error(`Therapist ${therapist.therapistID} has an invalid timezone: ${therapist.therapistTimeZone}`));
    }

    therapistStandardTimeZone = 'America/Chicago';
  }

  const { data: validatedTimes, isLoading } = useTherapistValidateTime({
    calendarID: therapist.acuityCalendarID,
    datetime: timestamp ? dayjs(timestamp).tz(therapistStandardTimeZone).format('YYYY-MM-DDTHH:mm:ssZ') : '',
    duration,
    timezone: contactTimezone,
    enabled: Boolean(timestamp),
  });

  if (isLoading) {
    return <LoadingText />;
  }

  if (!validatedTimes) {
    return null;
  }

  const relevantValidations =
    type === 'evaluation' ? validatedTimes.filter(v => dayjs(v.datetime).isSame(timestamp, 'day')) : validatedTimes;
  const timesWithConflicts = relevantValidations
    .sort((a, b) => dayjs(a.datetime).diff(dayjs(b.datetime)))
    .slice(0, maxCheckedDates ?? undefined)
    .filter(v => v.errors.length > 0)
    .map(v => v.datetime);

  if (timesWithConflicts.length === 0) {
    return null;
  }

  return (
    <div className={`text-sm flex flex-col gap-4 border border-gray-200 rounded-md p-4 ${className}`}>
      <span tw="bg-red-200 text-red-600 py-0.5 px-2 rounded-xl self-start text-xs" data-testid="conflict-header">
        {timesWithConflicts.length} CONFLICT{timesWithConflicts.length > 1 && 'S'}
        {override ? ' OVERRIDE' : ''}
      </span>
      <div data-testid="conflict-detail">
        {override
          ? 'The following sessions will be reserved and create schedule conflicts due to overrides:'
          : 'The following sessions will not be reserved because of schedule conflicts:'}
      </div>
      <ul data-testid="conflict-list">
        {timesWithConflicts.map(overlap => (
          <li tw="flex gap-1 items-center my-1" key={overlap}>
            <FontAwesomeIcon icon={faExclamationCircle} color="#CC3B37" />
            <span>{dayjs(overlap).tz(displayTimezone.value).format('MM/DD/YYYY - ddd - hh:mm A')}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ConflictWarning;
