import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/navbar/navbar';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';
import 'index.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import registerIcons from 'utils/register-fa';
import 'twin.macro';
import Amplify, { Auth } from 'aws-amplify';
import { ViewportProvider, CurrentUserProvider, FileToShareProvider } from '@expressable/utils';
import { ToastProvider } from 'react-toast-notifications';
import { Helmet } from 'react-helmet';
/* Pages */
import Dashboard from 'pages/dashboard';
import Contacts from 'pages/contacts';
import Contact from 'pages/contact';
import Clients from 'pages/clients';
import ClientCreate from 'pages/new-client-create';
import Client from 'pages/client';
import Credits from 'pages/credits';
import Therapists from 'pages/therapists';
import TherapistCreate from 'pages/therapist-create';
import Therapist from 'pages/therapist';
import ClaimCreate from 'pages/claim-create';
import NotFound from 'pages/page-not-found';
import { Toaster } from 'react-hot-toast';
import Resources from 'pages/resources';
import { useDisclosure } from '@expressable/ui-library';
import { NewVersionModal } from 'components/new-version-modal';
import Admin from 'pages/admin';
import { CustomToast } from 'components/CustomToast';
import { ErrorFallback } from 'components/ErrorBoundary';
import { ErrorBoundary } from 'react-error-boundary';
import { SESSION_MAX_IDLE_TIME } from 'domain/cognito/constants';
import * as Sentry from '@sentry/react';
import { initializeSentry } from 'utils/sentry';
import { IdleTimerContainer } from 'utils/on-idle-event';
import Footer from 'components/footer';
import Licenses from 'pages/licenses';

registerIcons();

initializeSentry();

interface AuthSchema {
  identityPoolId?: string;
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
}

const AuthConfig: AuthSchema = {
  region: process.env.REACT_APP_COGNITO_REGION_ID!,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
};

Amplify.configure({
  Auth: AuthConfig,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      staleTime: 300000,
      retry: (counter, error) => {
        return !(Object.values(error as Error)[2]?.status === 404 || counter == 2);
      },
    },
  },
});

function App() {
  /** ID user in Sentry */
  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      Sentry.setTag('isLoggedIn', true);
      Sentry.setUser({
        email: user?.attributes?.email,
      });
    });
  }, []);

  const resetAlert = () => {
    window.localStorage.setItem('user-has-been-alerted-for-new-build', 'false');
  };
  // the reason of this effect is to reset the alert every time the user refreshes the page, so that we avoid infinite loops
  useEffect(() => {
    window.addEventListener('beforeunload', resetAlert);
    return () => {
      window.removeEventListener('beforeunload', resetAlert);
    };
  }, []);

  const { isOpen: isResetModalOpen, onOpen: onOpenNewVersionModal, onClose: onCloseNewVersionModal } = useDisclosure();

  // listen for changes in the local storage by checking for the value every 1 second
  //FIXME this is not optimal, but it works for now
  useEffect(() => {
    const interval = setInterval(() => {
      const shoudDisplay = JSON.parse(window.localStorage.getItem('user-has-been-alerted-for-new-build') || 'false');
      if (shoudDisplay) onOpenNewVersionModal();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <QueryClientProvider client={queryClient}>
        <CurrentUserProvider>
          <ToastProvider components={{ Toast: CustomToast }} placement="bottom-left" autoDismissTimeout={5000}>
            <ViewportProvider>
              <FileToShareProvider>
                <IdleTimerContainer minutes={SESSION_MAX_IDLE_TIME} />
                <div data-testid="app" className="App" tw="font-sans">
                  <Helmet defaultTitle="Expressable Cue" titleTemplate="%s - Expressable Cue" />
                  <Router>
                    <NewVersionModal isOpen={isResetModalOpen} onClose={onCloseNewVersionModal} />
                    <Navbar />
                    <Switch>
                      <Route path="/" exact component={Dashboard} />
                      <Route path="/contacts" exact component={Contacts} />
                      <Route path="/contacts/:contactId" exact component={Contact} />
                      <Route path="/clients" exact component={Clients} />
                      <Route path="/client/create" exact component={ClientCreate} />
                      <Route path="/clients/:clientId" exact component={Client} />
                      <Route path="/therapists" exact component={Therapists} />
                      <Route path="/therapist/create" exact component={TherapistCreate} />
                      <Route path="/therapists/:therapistId" exact component={Therapist} />
                      <Route path="/academy/resources" exact component={Resources} />
                      <Route path="/claims" exact component={ClaimCreate} />
                      <Route path="/claims/create" exact component={ClaimCreate} />
                      <Route path="/credits" exact component={Credits} />
                      <Route path="/admin" exact component={Admin} />
                      <Route path="/licenses" exact component={Licenses} />
                      <Route path="*" component={NotFound} />
                    </Switch>
                    <Footer />
                  </Router>
                  <Toaster position="bottom-right" toastOptions={{ duration: 5000 }} />
                </div>
              </FileToShareProvider>
            </ViewportProvider>
          </ToastProvider>
        </CurrentUserProvider>
        <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default withAuthenticator(App);
