import { getAWeekAfterEvaluation, getMinDateForFirstRecurring } from 'domain/therapist-matcher/scheduling';
import { TimeSlot, useTherapistAvailableTimesV2 } from 'components/therapistMatcher/data';
import { useDisplayTimezone } from 'hooks/common/useDisplayTimezone';
import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import 'twin.macro';
import { Therapist } from 'types';
import DifferentTherapistSession from './different-therapist-session';
import OptionRadio from '../option-radio';
import SameTherapistSession from './same-therapist-session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export enum FirstRecurringOption {
  UseEvaluationTime = 'UseEvaluationTime',
  SameTherapist = 'SameTherapist',
  DifferentTherapist = 'DifferentTherapist',
  EvaluationOnly = 'EvaluationOnly',
}

export interface FirstRecurringSessionInfo {
  recurringOption: FirstRecurringOption | null;
  timestamp?: TimeSlot | null;
}

interface FirstRecurringSessionProps {
  evaluationTime: TimeSlot;
  therapists: Therapist[];
  value: FirstRecurringSessionInfo | null;
  onChange: (value: FirstRecurringSessionInfo) => void;
  onSkip?: () => void;
}

interface InternalState {
  recurringOption: FirstRecurringOption | null;
  sameTherapistTime?: TimeSlot | null;
  differentTherapistTime?: TimeSlot | null;
}

const FirstRecurringSession = ({ evaluationTime, therapists, value, onChange, onSkip }: FirstRecurringSessionProps) => {
  const { formatTime, displayTimezone } = useDisplayTimezone();

  const weekAfterEvaluation = getAWeekAfterEvaluation(evaluationTime.timestamp);

  const { data: timesOneWeekAfterEval, isLoading } = useTherapistAvailableTimesV2({
    calendarID: evaluationTime?.therapist?.acuityCalendarID,
    date: weekAfterEvaluation?.format('YYYY-MM-DD'),
    duration: 30,
    type: 'session',
  });

  const isAvailableOneWeekAfterEval = useMemo(() => {
    if (!timesOneWeekAfterEval?.items?.length) {
      return false;
    }

    return timesOneWeekAfterEval.items.some(date => dayjs(date.time).isSame(weekAfterEvaluation));
  }, [timesOneWeekAfterEval]);

  const evaluationTimeSession = useMemo(
    () => ({
      ...evaluationTime,
      timestamp: weekAfterEvaluation,
      duration: 30,
    }),
    [evaluationTime],
  );

  const moreThanOneTherapistFiltered = useMemo(() => {
    return therapists.length > 1;
  }, [therapists]);

  const evaluationTimeMessage = useMemo(() => {
    const therapistName = evaluationTimeSession.therapist.therapistName;
    const timestamp = evaluationTimeSession.timestamp;

    return `Schedule a recurring ${evaluationTimeSession.duration} minute session on ${formatTime(
      timestamp,
      'dddd',
    )}s at ${formatTime(evaluationTimeSession.timestamp)} (${
      displayTimezone.label
    }) with ${therapistName} SLP starting on ${formatTime(evaluationTimeSession.timestamp, 'M/D/YYYY')}`;
  }, [evaluationTimeSession]);

  const defaultInternalState = {
    recurringOption: null,
    sameTherapistTime: null,
    differentTherapistTime: null,
  };

  const { recurringOption, sameTherapistTime, differentTherapistTime } = useMemo((): InternalState => {
    if (!value || !value.recurringOption) {
      return defaultInternalState;
    }

    switch (value.recurringOption) {
      case FirstRecurringOption.UseEvaluationTime:
        return {
          recurringOption: FirstRecurringOption.UseEvaluationTime,
          sameTherapistTime: null,
          differentTherapistTime: null,
        };

      case FirstRecurringOption.SameTherapist:
        return {
          recurringOption: FirstRecurringOption.SameTherapist,
          sameTherapistTime: value.timestamp,
          differentTherapistTime: null,
        };

      case FirstRecurringOption.DifferentTherapist:
        return {
          recurringOption: FirstRecurringOption.DifferentTherapist,
          sameTherapistTime: null,
          differentTherapistTime: value.timestamp,
        };

      case FirstRecurringOption.EvaluationOnly:
        return {
          recurringOption: FirstRecurringOption.EvaluationOnly,
          sameTherapistTime: null,
          differentTherapistTime: null,
        };
    }
  }, [value]);

  if (isLoading) {
    return <FontAwesomeIcon icon={faCircleNotch} spin className="self-center my-8 flex-grow" />;
  }

  return (
    <div tw="flex flex-col gap-4">
      <div className="flex flex-col gap-4 w-max">
        {!evaluationTime.override && (
          <OptionRadio
            option={FirstRecurringOption.UseEvaluationTime}
            selectedOption={recurringOption}
            disabled={!isAvailableOneWeekAfterEval}
            onChange={() =>
              onChange?.({
                recurringOption: FirstRecurringOption.UseEvaluationTime,
                timestamp: evaluationTimeSession,
              })
            }
          >
            {evaluationTimeMessage}
          </OptionRadio>
        )}

        <OptionRadio
          option={FirstRecurringOption.SameTherapist}
          selectedOption={recurringOption}
          onChange={() =>
            onChange?.({
              recurringOption: FirstRecurringOption.SameTherapist,
              timestamp: null,
            })
          }
        >
          Schedule a recurring session with {evaluationTime.therapist.therapistName} SLP{' '}
          {evaluationTime.override ? '' : 'for a different day and time'}
        </OptionRadio>

        <OptionRadio
          option={FirstRecurringOption.DifferentTherapist}
          selectedOption={recurringOption}
          disabled={!moreThanOneTherapistFiltered}
          onChange={() =>
            onChange?.({
              recurringOption: FirstRecurringOption.DifferentTherapist,
              timestamp: null,
            })
          }
        >
          Schedule a recurring session with a different therapist
        </OptionRadio>

        <OptionRadio
          option={FirstRecurringOption.EvaluationOnly}
          selectedOption={recurringOption}
          onChange={() =>
            onChange?.({
              recurringOption: FirstRecurringOption.EvaluationOnly,
              timestamp: null,
            })
          }
          testId="schedule-only-the-evaluation-with-therapist"
        >
          Schedule only the evaluation with {evaluationTime.therapist.therapistName}
        </OptionRadio>
      </div>

      {recurringOption === FirstRecurringOption.SameTherapist && (
        <SameTherapistSession
          value={sameTherapistTime}
          onChange={newTimestamp =>
            onChange?.({
              ...(value ?? defaultInternalState),
              timestamp: newTimestamp,
            })
          }
          therapist={evaluationTime.therapist}
          minDateAllowed={getMinDateForFirstRecurring(evaluationTime.timestamp)}
          onSkip={onSkip}
        />
      )}

      {recurringOption === FirstRecurringOption.DifferentTherapist && (
        <DifferentTherapistSession
          therapists={therapists}
          value={differentTherapistTime}
          onChange={newTimestamp =>
            onChange?.({
              ...(value ?? defaultInternalState),
              timestamp: newTimestamp,
            })
          }
          evaluationTime={evaluationTime}
          onSkip={onSkip}
        />
      )}
    </div>
  );
};

export default FirstRecurringSession;
